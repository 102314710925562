<template>
  <article class="article" v-lazy-container="{ selector: 'img' }">
    <section class="article__mood">
      <img :data-src="getImagePath('mood.jpg')" class="article__mood-image">
      <h1 class="article__mood-title">{{ $t('ensemble.title') }}</h1>
    </section>
    <section class="article__text article--bg-corporate-medium special">
      <p data-aos="fade-up">{{ $t('ensemble.special_text') }}</p>
      <h2 data-aos="fade-up">{{ $t('ensemble.special_title') }}</h2>
    </section>
    <section class="article__columns">
      <div class="article__columns-item architecture01">
        <img :data-src="getImagePath('architecture01.jpg')">
      </div>
      <div class="article__columns-item architecture02">
        <img :data-src="getImagePath('architecture02.jpg')">
      </div>
    </section>
    <section class="article__text architecture03">
      <h2 data-aos="fade-up">{{ $t('ensemble.architecture_title') }}</h2>
      <p data-aos="fade-up">{{ $t('ensemble.architecture_text') }}</p>
    </section>
    <section class="article__mood">
      <div data-aos="fade-up" class="house__overview">
        <h4 v-for="(item, i) in this.json.ensemble.house_overview" :class="['house__label', `house__label-${i+1}`]" :key="`label${i}`">
          <span>
            <span v-if="item.subtitle">{{ item.subtitle }}</span>
            <strong>{{ item.title }}</strong>
          </span>
        </h4>
        <picture>
          <source media="(max-width: 830px)" :srcset="`${house_overviewsrcXS}`">
          <source media="(min-width: 830px)" :srcset="`${house_overviewsrc}`">
          <img :data-src="`${house_overviewsrc}`" alt="">
        </picture>
      </div>
    </section>
    <section>
      <div ref="houses" class="house__container">
        <div v-for="(item, i) in this.json.ensemble.house_list" :class="['article__columns','house', {'house--active': activeHouse == i}]" :key="`house${i}`">
          <button @click="toggleHouses(i)" :aria-selected="activeHouse == i" role="tab" :class="['house__tab', {'house__tab--active': activeHouse == i }]">{{ item.label }}</button>
          <div class="article__columns article__columns--stretch">
            <div class="house__row">
              <div class="article__columns-item">
                <h2>{{ item.title }}<span v-if="item.subtitle" class="house__subtitle">{{ item.subtitle }}</span></h2>
              </div>
              <div class="article__columns-item">
                <ul class="ul--dash">
                  <li v-for="(point, k) in item.bullet_list" :key="`point${k}`">
                    {{ point }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="house__row house__row--no-margin">
              <div class="article__columns-item">
                <img :data-src="require(`../assets/images/ensemble/house_plan0${i+1}.svg`)" width="700" alt="" class="house__plan">
              </div>
              <div class="article__columns-item">
                <img :data-src="require(`../assets/images/ensemble/house0${i+1}.jpg`)" class="house__image" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: 'Ensemble',
  metaInfo() {
    return {
      title: this.$t('ensemble.meta_title'),
      meta: [
        { name: 'description', content:  this.$t('global.meta_description') },
        { name: 'keywords', content:  this.$t('global.meta_keywords') }
      ]
    }
  },
  data () {
    return {
      activeHouse: 0,
      house_overviewsrc: require('../assets/images/ensemble/house_overview.jpg'),
      house_overviewsrcXS: require('../assets/images/ensemble/house_overview-xs.jpg'),
    }
  },
  created () {
    if(this.$i18n.locale === 'en'){
      this.json = require('@/locales/en.json');
    }
    else{
      this.json = require('@/locales/de.json');
    }
  },
  methods: {
    getImagePath(src) {
      return require(`../assets/images/ensemble/${src}`)
    },
    toggleHouses (index) {
      this.activeHouse = index

      if (window.innerWidth < 768) {
        let articleChildren = Array.from(document.getElementsByClassName('article')[0].children)
        let height = 0

        articleChildren.forEach(function(item, index){
          if (index <= 4) {
            height += item.clientHeight
          }
        })

        setTimeout(function() {
          let scrollTop = height + (index * 103)
          window.scrollTo({ top: scrollTop, behavior: 'smooth' });
        }, 300);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
$bubble-breakpoint: 830px;

.article__mood {
  h1 {
    color: $color-corporate;
  }
}

.special {
  @include tablet {
    padding-bottom: 3rem;
  }

  @include grid-spacing('desktop', 0, 0);
  @include grid-spacing('desktop', 2, 2, 'padding-right');
  @include grid-spacing('desktop-xl', 1, 1, 'padding-right');
  @include grid-spacing('desktop-xl', 1, 1);
  @include font-size(18);

  font-weight: 600;
  padding-bottom: 6rem;
  padding-left: 0;

  h2 {
    @include grid-spacing('tablet', 5, 2);
    @include grid-spacing('desktop', 8, 7);
    @include grid-spacing('desktop', 3, 3, 'margin-right', '-');
    @include grid-spacing('desktop-xl', 6, 6);

    color: $color-shade-lightest;
  }
}

.architecture01 {
  @include grid-spacing('mobile', 3, 2, 'padding-right');
  @include grid-spacing('tablet', 1, 0, 'padding-right');

  img {
    @include tablet {
      margin-top: -14rem;
    }

    @include desktop {
      margin-top: -18rem;
    }

    margin-top: -4rem;
  }
}

.architecture02 {
  @include grid-spacing('mobile', 3, 2, 'padding-left');

  @include tablet {
    padding-top: 6rem;
  }

  @include grid-spacing('desktop-xl', 1, 1, 'padding-right');
  @include desktop-xl {
    padding-left: $container-padding-desktop;
    margin-left: 0;
  }

  padding-left: 0;
  padding-top: $container-padding;

  img {
    @include from-until($desktop, $desktop-xl) {
      margin-left: -85px;
      width: calc(100% + 85px);
      max-width: none;
    }
  }
}

.architecture03 {
  @include tablet {
    margin-top: -16rem;
  }

  margin-top: -4rem;
}

.ul--dash {
  padding-right: 80px;
}

.house {
  display: flex;
  flex-direction: column;

  &__subtitle {
    @include desktop {
      font-size: 1.67rem;
    }
    @include font-size(20);

    font-family: $corporate-font-family;
    display: block;
  }

  .article__columns {
    @include tablet {
      overflow: visible;
      max-height: 0;
      opacity: 0;
      transition: opacity .8s 0s $transition-easing;
    }

    max-height: 0;
    overflow: hidden;
    transition: max-height .8s 0s $transition-easing;
    z-index: 0;
  }

  .article__columns-item {
    @include until($tablet) {
      padding-top: $container-padding;
    }

    @include tablet() {
      padding-bottom: $container-padding;
    }

    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: auto;

    &:nth-child(1) {
      @include tablet {
        padding-top: 4rem;
      }
    }

    &:nth-child(2) {
      @include until($tablet) {
        margin-bottom: 1rem;
      }

      @include tablet() {
        padding-top: 6rem;
      }

      position: relative;

      * {
        position: relative;
      }
    }
  }

  &--active {
    .article__columns {
      @include tablet {
        max-height: auto;
        opacity: 1;
        z-index: 2;
      }

      max-height: 2000px;
      overflow: visible;
    }
  }

  &__row {
    @include tablet {
      display: flex;
    }

    width: 100%;

    &--no-margin.house__row {
      .article__columns-item {
        @include tablet {
          padding-top: 0;
        }
      }
    }
  }

  &__overview {
    @include grid-spacing('desktop-xl', 1, 1);
    @include grid-spacing('desktop-xl', 1, 1, 'padding-right');

    position: relative;
    width: 100%;
  }

  &__label {
    $diff-x: 40px;
    $diff-y: 200px;
    $bubble: 130px;
    $line: 100px;
    $bubble-delay: .7s;

    @include from($bubble-breakpoint) {
      display: block;
    }

    position: absolute;
    display: none;

    &::before {
      content: '';
      position: absolute;
      width: $bubble;
      height: $bubble;
      background-color: $color-shade-lightest;
      border-radius: 50%;
      top: 0;
      left: div($bubble, -2);
      transition: transform .7s $bubble-delay ease-in-out;
      transform-origin: center;
      transform: scale(0);

      .aos-animate & {
        transform: scale(1);
      }
    }

    &::after {
      content: '';
      width: 3px;
      height: $line;
      position: absolute;
      left: 0;
      top: $line;
      background-color: $color-shade-lightest;
      opacity: 0;
      transition: opacity .7s ($bubble-delay + .4) ease-in-out;

      .aos-animate & {
        opacity: 1;
      }
    }

    &-1 {
      @include desktop {
        top: calc(44vw - #{$diff-y});
        left: calc(20% - #{$diff-x});
      }

      @include desktop-xl {
        top: 350px;
        left: 290px;
      }

      top: calc(51vw - #{$diff-y});
      left: calc(20% - #{$diff-x});

      &::after {
        bottom: -30px;
        top: auto;
      }
    }

    &-2 {
      @include desktop {
        top: calc(24.5vw - #{$diff-y});
        left: calc(41.3% - #{$diff-x});
      }

      @include desktop-xl {
        top: 90px;
        left: 547px;
      }

      top: calc(24.5vw - #{$diff-y});
      left: calc(43.3% - #{$diff-x});

      &::before {
        transition-delay: $bubble-delay + .2;
      }
    }

    &-3 {
      @include desktop {
        top: calc(53vw - #{$diff-y});
        left: calc(53% - #{$diff-x});
      }

      @include desktop-xl {
        top: 460px;
        left: 685px;
      }

      top: calc(60vw - #{$diff-y});
      left: calc(56% - #{$diff-x});

      &::before {
        transition-delay: $bubble-delay + .4;
      }

      &::after {
        bottom: -30px;
        top: auto;
      }
    }

    &-4 {
      @include desktop {
        top: calc(32vw - #{$diff-y});
        left: calc(79% - #{$diff-x});
      }

      @include desktop-xl {
        top: 180px;
        left: 970px;
      }

      top: calc(32vw - #{$diff-y});
      left: calc(79% - #{$diff-x});

      &::before {
        transition-delay: $bubble-delay + .6;
      }
    }

    > span {
      @include font-size(18);

      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-family: $corporate-font-family-strong;
      line-height: 1.4;
      letter-spacing: .03em;
      left: div($bubble, -2);
      width: $bubble;
      height: $bubble;
      opacity: 0;
      transition: opacity .7s ($bubble-delay + .7) ease-in-out;

      .aos-animate & {
        opacity: 1;
      }

      > span {
        font-family: $corporate-font-family;
      }
    }

    strong {
      position: relative;
      white-space: nowrap;
      font-family: $corporate-font-family-strong;
    }
  }

  &__container {
    @include grid-spacing('desktop-xl', 1, 1, 'padding-right');
    @include grid-spacing('desktop-xl', 1, 1, 'border-left-width');

    border-style: solid;
    border-color: transparent;
    border-width: 0;
    position: relative;
    margin-top: 3rem;
  }

  &__image {
  }

  &__plan {
    padding-right: 30px;
  }

  &__tab {
    @include tablet {
      @include font-size(16);

      width: 140px;
      position: absolute;
      left: 0;
      justify-content: center;
      padding: .3em;
      border-width: 0;
      z-index: 10;
      top: 0;

      @for $i from 0 through 3 {
        .house:nth-child(#{$i + 1}) & {
          left: 164px * $i + 24;
        }
      }
    }

    @include desktop {
      @for $i from 0 through 3 {
        .house:nth-child(#{$i + 1}) & {
          left: 164px * $i + 24;
        }
      }
    }

    @include font-size(18);

    align-items: center;
    background-color: $color-shade-lighter;
    border: 1px solid $color-shade-darkest;
    border-width: 1px 0 0;
    color: $color-corporate;
    cursor: pointer;
    display: flex;
    font-family: $corporate-font-family;
    justify-content: space-between;
    margin: 0 -#{$container-padding};
    padding: 16px $container-padding;
    text-align: left;
    width: 100vw;

    &::after {
      @include tablet {
        display: none;
      }

      $size: 16px;
      content: '';
      width: $size;
      height: $size;
      border: 1px solid $color-corporate;
      border-width: 1px 1px 0 0;
      transform: rotateZ(45deg);
      display: block;
    }

    &--active {
      background-color: $color-corporate;
      color: $color-shade-lightest;
      font-weight: 600;

      &::after {
        border-color: $color-shade-lightest;
        border-width: 2px 2px 0 0;
        transform: rotateZ(135deg);
      }
    }
  }
}
</style>
